import React,{useEffect} from 'react'
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import ImageGallery from '@src/components/templates/propertyAndProjectPage/ImageGallery'
import withRoot from '@src/withRoot'


const GalleryPageTemplate = (props) => {
    const {
        propertyStore,
        generalPropertyStore,
        uiStore,
        appStore,
        pageContext: { data:passedData , planId}
    } = props

    const data = propertyStore.additionalProcess(generalPropertyStore.processData(passedData))
    propertyStore.setCurrentDetails(data)
    useEffect(() => {
        uiStore.setExplorePageCurrentTab(null)
    }, [])

    useEffect(() => {
        propertyStore.initPropertyDetailData_(passedData)
    }, [appStore.loggedInUser])
    
    return (
        <div>
            <ImageGallery planId={planId}/>
        </div>
    )
}

export default compose(
    withRoot,
    inject('propertyStore','generalPropertyStore','uiStore','appStore'),
    observer
)(GalleryPageTemplate)
